import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  /*
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  */
  {
    path: '/',
    name: 'Contest',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contest" */ '../views/Contest.vue')
  },
  {
    path: '/login-twitch',
    name: 'LoginTwitch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "loginTwitch" */ '../views/LoginTwitch.vue')
  },
  {
    path: '/overlay/:token/:direction',
    name: 'Overlay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "overlay" */ '../views/Overlay.vue')
  },
  
  {
    path: '/:contest/rules',
    name: 'Rules',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contest" */ '../views/Rules.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
