export default  {
    namespaced: true,
    state: () => ({
      texts: [],
      categories: [],
      prizes: [],
      winners: [],
      pages: [],
      streamers: [],
      lastLoad: null,
      loadTimeout: 60
    }),
    mutations: {
      setPages (state, payload) {
        state.pages = payload
      },
      setPrizes (state, payload) {
        state.prizes = payload
      },
      setCategories (state, payload) {
        state.categories = payload
      },
      setWinners (state, payload) {
        state.winners = payload
      },
      setStreamers (state, payload) {
        state.streamers = payload
      },
      setLastLoad (state) {
        state.lastLoad = new Date().getTime();
      }
    },
  
    getters: {
      getPageContent: (state) =>  (page_slug, content_key) => {
        let page = state.pages.find(p => p.slug == page_slug);
        if (page) {
            let content = page.content.find(c => c.name == content_key);
            if (content) {
                return content.value;
            }
        }
        return null;
      }
    },
    actions: {
        load(context) { 
            let _timeout = (new Date().getTime()) - (context.state.loadTimeout * 1000);
            if (!context.state.lastLoad || context.state.lastLoad < _timeout) {
              let contest = context.rootState.contest;
              this._vm.$api.get(`/content/${contest}`)
                  .then(res => res.data)
                  .then(data => {
                      console.log(data);
                      context.commit('setPages', data.pages);
                      context.commit('setPrizes', data.prizes);
                      context.commit('setCategories', data.categories);
                      context.commit('setWinners', data.winners);
                      context.commit('setStreamers', data.streamers);
                      context.commit('setLastLoad');
                  })
            }

        }
    }
  }