const axios = require('axios');
import store from '../store'


export default {
    // called by Vue.use(FirstPlugin)
    install(Vue) {
        console.log(process.env)
        Vue.prototype.$api = axios.create({
            baseURL: process.env.VUE_APP_STREAMCODE_API_URL,
            timeout: 15000
        })

        Vue.prototype.$api.interceptors.request.use(function(config) {
            if (store.state.accessToken) {
                if (typeof config.headers !== 'object') {
                    config.headers = {}
                }
                config.headers['Authorization'] = `Bearer ${store.state.accessToken}`
            }
            
            return config;
        })
    }
}