import Vue from 'vue'
import Vuex from 'vuex'

import VuexPersistence from 'vuex-persist'

import contentModule from './content'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    content: contentModule
  },
  state: {
    contest: null,
    accessToken: null,
    user: null,
    loadingUser: false,
    idToken: null,
    cards: []
  },
  mutations: {
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setIdToken(state, payload) {
      state.idToken = payload;
    },
    setContest(state, payload) {
      state.contest = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setCards(state, payload) {
      state.cards = payload;
    },
    addCard(state, payload) {
      state.cards.push(payload);
    },
    setLoadingUser(state, payload) {
      state.loadingUser = payload;
    }
  },
  actions: {
    validateToken(context, payload) {
      context.commit('setLoadingUser', true)
      context.commit('setAccessToken', payload.accessToken)
      context.commit('setIdToken', payload.idToken)

      this._vm.$api.post('me', {'id_token': payload.idToken})
        .then(res => res.data)
        .then(data => {
          if (!data.error) {
            context.commit('setUser', data.user)
            context.commit('setCards', data.cards)
            context.commit('setLoadingUser', false)
            this._vm.$gtag.event('login', {
              'method': 'Twitch'
            })
          }
          else {
            this._vm.$gtag.exception({
                'description': `POST /me RESPONSE: ${data.error}`,
                'fatal': true
            })
            console.log(data.error);
          }
        })
        .catch(err => {
          this._vm.$gtag.exception({
              'description': `POST /me: ${err}`,
              'fatal': false
          })
          console.log(err);
          context.dispatch('logout');
        })
    },
    logout(context) {
      context.commit('setAccessToken', null)
      context.commit('setIdToken', null)
      context.commit('setUser', null)
      context.commit('setCards', [])
      context.commit('setLoadingUser', false)
    }
  },
  plugins: [vuexLocal.plugin]
})
